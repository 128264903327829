import { render, staticRenderFns } from "./review.vue?vue&type=template&id=349434c9&scoped=true"
import script from "./review.vue?vue&type=script&lang=js"
export * from "./review.vue?vue&type=script&lang=js"
import style0 from "./review.vue?vue&type=style&index=0&id=349434c9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "349434c9",
  null
  
)

export default component.exports